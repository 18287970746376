import { useEffect, useState, useCallback } from "react";
import apiClient from "../api/api-client";
import documentsListResponse from "../api/documents-list-response";
import documentFieldsResponse from "../api/document-fields-response";
import useAsync from "../hooks/use-async";
import { format } from "date-fns";

export default function useDocumentsList(documentTypeId, page = 1, limit = 50, sortColumn = null, sortDir = null, filters = null, links = null, queryFilterId = null, documentFilterId = null, onReloaded) {
    const fields = useAsync();
    const list = useAsync();
    const [ savedColumns, setSavedColumns ] = useState([]);
    const [ loadedFields, setLoadedFields ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        if (!documentTypeId) return;
        setLoadedFields(false);
        fields.run(apiClient(`document-types/${documentTypeId}`))
            .then(() => setLoadedFields(true))
            .catch(() => { });
    }, [documentTypeId]);

    useEffect(() => {
        if (!documentTypeId) {
            setSavedColumns([]);
            return;
        }

        try {
            let s = window.localStorage.getItem(`columns:${documentTypeId}`);
            s = s ? JSON.parse(s) : [];
            setSavedColumns(s);
        } catch (error) {
            setSavedColumns([]);
        }
    }, [ documentTypeId ]);

    const reload = () => {
        const params = { 
            condition: filters?.type, 
            filters: filters?.filters?.filter(i => i?.type !== "document_type")?.map(mapFilter),
            links: filters?.filters?.filter(i => i?.type === "document_type")?.map(i => ({
                operator: "=",
                document_id: i.value?.value,
            })),
        };

        return list.run(apiClient(`workflow/document-type/${documentTypeId}/documents?page=${page}&per_page=${limit}&sort=${sortColumn}&dir=${sortDir}`, { method: "POST", data: params }))
            .then(() => {
                if (loaded && onReloaded) {
                    onReloaded();
                }
            })
            .then(() => setLoaded(true))
            .catch(() => { });
    };

    const exportList = (format) => {
        const params = { 
            condition: filters?.type, 
            filters: filters?.filters?.filter(i => i?.type !== "document_type")?.map(mapFilter),
            links: filters?.filters?.filter(i => i?.type === "document_type")?.map(i => ({
                operator: "=",
                document_id: i.value?.value,
            })),
        };

        return apiClient(`workflow/document-type/${documentTypeId}/documents?export=${format}&page=${page}&per_page=${limit}&sort=${sortColumn}&dir=${sortDir}`, { method: "POST", data: params, blob: true });
    };

    useEffect(() => {
        if (!documentTypeId) return;
        if (queryFilterId && !filters?.type) return;
        if (documentFilterId && !filters?.type) return;
        if (sortDir == null) return;
        if (sortColumn == null) return;
        reload();
    }, [documentTypeId, filters, page, limit, sortColumn, sortDir, queryFilterId]);

    const toggleColumn = useCallback(id => {
        const newColumns = savedColumns.includes(id) ? savedColumns.filter(i => id != i) : [ ...savedColumns, id ];
        window.localStorage.setItem(`columns:${documentTypeId}`, JSON.stringify(newColumns));
        setSavedColumns(newColumns);
    });

    return {
        documents: list.data ? documentsListResponse(list.data) : null,
        fields: fields.data ? documentFieldsResponse(fields.data) : [],
        columns: fields.data ? fields.data.map(field => ({
            ...field,
            id: field.id,
            label: field.label,
            isSelected: savedColumns.includes(field.id),
            name: field.name,
            type: field.field.type,
        })).filter(i => i.name !== "name" && i?.field?.type !== "section") : [],
        toggleColumn: toggleColumn,
        isPending: list.isIdle || list.isPending || fields.isIdle || fields.isPending,
        isError: list.isError || fields.isError,
        isSuccess: list.isSuccess && fields.isSuccess,
        savedColumns,
        isIdle: list.isIdle || fields.isIdle,
        isLoadedFields: loadedFields,
        reload,
        exportList,
        pagination: {
            total: list?.data?.meta?.total,
            per_page: list?.data?.meta?.per_page,
        },
    };
}

function mapFilter(i) {
    const specialDates = [ "BEFORE_YESTERDAY", "YESTERDAY", "TODAY", "TOMORROW", "AFTER_TOMORROW", "IS_EMPTY" ];
    if (i.type === "date" && specialDates.includes(i.operator.toUpperCase())) {
        return mapDateFilter(i);
    }

    let value = i.value;
    let operator = i.operator.toLowerCase();

    if (i.operator.toUpperCase() === "IS_EMPTY") {
        value = "";
        operator = "is";
    }

    if (i.operator.toUpperCase() === "IS_NOT_EMPTY") {
        value = "";
        operator = "is_not";
    }

    return {
        name: i.column,
        operator,
        value,
    };
}

function mapDateFilter(i) {
    let value = i.value;
    let operator = "is";
    let today = new Date();
    const dateFormat = "yyyy-MM-dd";

    switch (i.operator.toUpperCase()) {
    case "IS_EMPTY":
        value = null;
        break;
    case "TODAY":
        value = format(today, dateFormat);
        break;
    case "YESTERDAY":
        today.setDate(today.getDate() - 1);
        value = format(today, dateFormat);
        break;
    case "BEFORE_YESTERDAY":
        today.setDate(today.getDate() - 2);
        value = format(today, dateFormat);
        break;
    case "TOMORROW":
        today.setDate(today.getDate() + 1);
        value = format(today, dateFormat);
        break;
    case "AFTER_TOMORROW":
        today.setDate(today.getDate() + 2);
        value = format(today, dateFormat);
        break;
    }

    return {
        name: i.column,
        operator,
        value,
    };
}