import { jsx } from "@emotion/core";
import React, { useState } from "react";
import Popup from "@atlaskit/popup";
import { NotificationIndicator } from "@atlaskit/notification-indicator";
import { NotificationLogClient } from "@atlaskit/notification-log-client";
import { Notifications } from "@atlaskit/atlassian-navigation";
import { default as NotificationsList } from "./notifications";
import apiClient from "../../api/api-client";

const wrapperCSS = {
    width: 460,
    maxHeight: "calc(100vh - 100px)",
    padding: "10px",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto"
};

const NotificationsContent = () => (
    <div style={wrapperCSS}>
        <NotificationsList />
    </div>
);

class MockNotificationLogClient extends NotificationLogClient {
    constructor() {
        super("", "");
    }

    async countUnseenNotifications() {
        const counted = localStorage.getItem("counted");

        if (counted) {
            const now = Date.now();
            const diff = now - parseInt(counted);
            
            if (diff < 60 * 1000) {
                return { count: 0 };
            }
        }

        return apiClient("notifications/unread/counted")
            .then(response => {
                localStorage.setItem("counted", Date.now());
                return { count: response.counted };
            })
            .catch(() => ({ count: 0 }));
    }
}

const client = new MockNotificationLogClient();

export const NotificationsPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [buttonLabel, setButtonLabel] = useState();

    const updateButtonLabel = ({ newCount }) => {
        setButtonLabel(newCount || 0);
    };

    const NotificationsBadge = () => (
        <NotificationIndicator
            onCountUpdated={updateButtonLabel}
            notificationLogProvider={Promise.resolve(client)}
        />
    );

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Popup
            placement="bottom-end"
            content={NotificationsContent}
            isOpen={isOpen}
            rootBoundary="viewport"
            onClose={onClose}
            trigger={triggerProps => (
                <Notifications
                    badge={NotificationsBadge}
                    onClick={onClick}
                    tooltip={`Powiadomienia (${buttonLabel})`}
                    isSelected={isOpen}
                    {...triggerProps}
                />
            )}
        />
    );
};
