/* eslint-disable no-case-declarations */
import React, { useCallback, useState } from "react";
import { Field } from "@atlaskit/form";
import TextArea from "@atlaskit/textarea";
import Select from "@atlaskit/select";
import { Checkbox } from "@atlaskit/checkbox";
import { DatePicker } from "@atlaskit/datetime-picker";
import TextField from "@atlaskit/textfield";
import styled from "styled-components";
import FormTable from "./form-table";
import { Editor, WithEditorActions, EditorContext } from "@atlaskit/editor-core";
import { JIRATransformer } from "@atlaskit/editor-jira-transformer";
import { resourceProvider } from "../../utils/mention-resource";
import MultiSelect from "@atlaskit/multi-select";
import DocumentTable from "../documents/document-table";
import SearchDocumentsByType from "../documents/search-documents-by-type";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import HipchatChevronUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-up";
import SignatureCanvas from "react-signature-canvas";
import Button from "@atlaskit/button/custom-theme-button";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import Tooltip from "@atlaskit/tooltip";

const mentionProvider = Promise.resolve(resourceProvider);

function FormField({ spacer = false, field, inputProps, onChange, document, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave, value, propagateFields, setDocumentsToUpdateAfterSave, sectionHidden = false, sectionHiddenCreate = false, onSectionToggle, onClear, noLabel = false }) {
    if (sectionHiddenCreate) return null;
    
    let sigPad = {};
    
    if (field.field.type === "section") {
        return <Section onClick={() => onSectionToggle && onSectionToggle(field?.id)}>
            <SectionTitle>{field.label}</SectionTitle>
            <SectionIcon>
                {sectionHidden ? <HipchatChevronDownIcon /> : <HipchatChevronUpIcon />}
            </SectionIcon>
        </Section>;
    }

    if (sectionHidden) {
        return null;
    }

    if (field.field.type === "select") {
        inputProps.defaultValue = field.options
            .map(i => ({ label: i.name, value: i.value }))
            .find(i => i.value == value);
    }

    if (field.field.type === "multiselect") {
        const selectedValues = inputProps?.rawValues?.filter(i => i?.document_type_field_id === field?.id)?.map(i => i?.value) || [];
        inputProps.defaultSelected = field.options
            .map(i => ({ content: i.name, value: i.value }))
            .filter(i => selectedValues?.includes(i?.value));
    }

    if (field.field.type === "table") {
        return <FormTable {...inputProps} label={field.label} table_column={field?.table_column} onChange={data => {
            onChange && onChange(field, data);
        }} />;
    }

    const rawOptions = field?.options?.[0];
    let optionsData = {};
    try {
        optionsData = JSON.parse(rawOptions);
    } catch (e) {
        //
    }

    const propagateField = (data) => {
        const valuesMap = data?.values?.reduce((acc, i) => {
            acc[i?.name] = i?.value;
            return acc;
        }, {});

        propagateFields && propagateFields(field, valuesMap);
    };

    const onValueChange = e => onChange(field, e?.target?.value);
    const onSigCreated = useCallback((ref) => {
        sigPad = ref;
        if (sigPad) {
            sigPad.fromDataURL(value);
        }
    },[]);
    const saveImageFromSigPad = useCallback(() => {
        onChange(field, sigPad.getTrimmedCanvas().toDataURL("image/png"));
    },[]);

    const clearSigPad = useCallback(() => {
        onChange(field, null);
        sigPad.clear();
    },[]);

    const help = field?.help?.length > 0 &&
        <HelpWrapper>
            <Tooltip content={field?.help}>
                <QuestionCircleIcon size="small" />
            </Tooltip>
        </HelpWrapper>;

    const label = <Label>
        <LabelWrapper>
            {field.label}
            {!!field.required && <RequiredLabel>*</RequiredLabel>}
            {help}
        </LabelWrapper>
    </Label>;
    
    return <Wrapper>
        <FieldWrapper>
            {noLabel ? null : label}
            <Field {...inputProps} >
                {({ fieldProps }) => { 
                    switch (field.field.type) {
                    case "text":
                        return <TextField {...fieldProps} autoFocus={field.name == "name"} onChange={onValueChange} value={value} summary={field?.help} />;
                    case "password":
                        return <TextField {...fieldProps} onChange={onValueChange} value={value} summary={field?.help} type="password"  />;
                    case "number":
                        return <TextField type="number" {...fieldProps} step="any" onChange={onValueChange} value={value} />;
                    case "textarea":
                        return <TextArea {...fieldProps} onChange={onValueChange} value={value} />;
                    case "select":
                        return <Select
                            {...fieldProps}
                            onChange={(e) => {
                                onChange(field, e?.value);
                            }}
                            options={field.options.map(i => ({ label: i.name, value: i.value }))}
                        />;
                    case "multiselect":
                        const selectedValues = inputProps?.rawValues?.filter(i => i?.document_type_field_id === field?.id)?.map(i => i?.value) || [];
                        return <MultiSelect 
                            {...fieldProps}
                            shouldFitContainer
                            defaultSelected={inputProps?.defaultSelected || []}
                            onSelectedChange={e => {
                                onChange && onChange(field, e?.items?.map(i => i?.value));
                            }}
                            items={field.options?.sort((a, b) => {
                                const aIndex = selectedValues?.findIndex(i => i === a?.value);
                                const bIndex = selectedValues?.findIndex(i => i === b?.value);
                                
                                return aIndex > bIndex;
                            })?.map(i => ({
                                content: i?.name, 
                                value: i?.value,
                            }))} />;
                    case "date":
                        return <DatePicker {...fieldProps} locale="pl-PL" value={value} onChange={e => {
                            onChange(field, e);
                        }} />;
                    case "checkbox":
                        return <Checkbox {...fieldProps} 
                            defaultChecked={inputProps.defaultValue} 
                            defaultValue={true} 
                            value={value}
                            onChange={e => {
                                onChange(field, e?.target?.checked);
                            }}
                            label={field?.label ?? field?.options?.label} />;
                    case "wysywig":
                        return <EditorContext>
                            <WithEditorActions
                                render={actions => (
                                    <Editor 
                                        {...fieldProps}
                                        onChange={async e => {
                                            const value = await actions.getValue();
                                            onChange && onChange(field, value);
                                        }}
                                        defaultValue={inputProps.defaultValue}
                                        allowExpand
                                        allowConfluenceInlineComment
                                        autoScrollIntoView
                                        // mentionProvider={mentionProvider}
                                        contentTransformerProvider={schema =>
                                            new CustomJiraTransformer(schema, 
                                                // { mention: mentionEncoder }
                                            )
                                        }
                                        // mentionInsertDisplayName={false}
                                        appearance="comment" />
                                )} />
                        </EditorContext>;
                    case "document-type":
                        if (!rawOptions) return "-";
                        if (!optionsData?.documentTypeId) return "-";
                        return <SearchDocumentsByType 
                            autoFocus={false}
                            direction={optionsData?.dir ?? -1}
                            onChange={e => {
                                if (e) {
                                    onChange(field, e.value, e?.data);
                                    propagateField(e?.data);
                                } else {
                                    onChange(field, null, {});
                                }
                            }}
                            selectedDocumentId={parseInt(value) ?? parseInt(inputProps.defaultValue)}
                            selectedDocumentTypeId={parseInt(optionsData?.documentTypeId)}
                            typeId={optionsData?.documentTypeId} {...inputProps} />;
                    case "signature":
                        if (!value) {
                            return <div>
                                <SigPad>
                                    <SignatureCanvas ref={onSigCreated} penColor="blue" canvasProps={{width: 500, height: 200}} />;
                                </SigPad>
                                <Button appearance="primary" onClick={saveImageFromSigPad}>
                                    {"Gotowy podpis"}
                                </Button>
                                <Button appearance="primary" onClick={clearSigPad}>
                                    {"Wyczyść"}
                                </Button>
                            </div>;
                        }

                        return <SigPad>
                            <img src={value} width={500} height={200}/>
                            <Button appearance="primary" onClick={() => {onChange(field, null);}}>
                                {"Wyczyść"}
                            </Button>
                        </SigPad>;
                    case "list":
                        if (!document) return "-";
                        if (!rawOptions) return "-";

                        return <DocumentTable 
                            document={document} 
                            xId={optionsData?.document_type_id} 
                            name={optionsData?.label}
                            summary={optionsData?.summary}
                            onLinkRemoved={linkId => {
                                setDocumentsToLinkAfterSave(data => data?.filter(d => {
                                    if (d?.data?.id === linkId) return false;
                                    return true;
                                }));
                            }}
                            onRemoved={linkId => {
                                setDocumentsToUnlinkAfterSave(d => ([ ...d, linkId ]));
                            }}
                            onEdited={(recordToEdit, formData) => {
                                setDocumentsToLinkAfterSave(data => data?.map(d => {
                                    if (d?.data?.id === recordToEdit?.id) {
                                        return {
                                            ...d,
                                            data: {
                                                ...d?.data,
                                                ...formData,
                                            }
                                        };
                                    }

                                    return d;
                                }));
                            }}
                            onUpdated={(recordToEdit, formData) => {
                                setDocumentsToUpdateAfterSave(data => {
                                    const record = data?.find(i => i?.id == recordToEdit?.id);

                                    if (!record) {
                                        data.push({
                                            id: recordToEdit?.id,
                                            ...formData,
                                        });
                                    } else {
                                        data = data?.map(d => {
                                            if (d?.id == recordToEdit?.id) {
                                                return {
                                                    id: recordToEdit?.id,
                                                    ...formData,
                                                };
                                            }
                                            return d;
                                        });
                                    }
                                    
                                    return data;
                                });
                                setDocumentsToUpdateAfterSave(data => data?.map(d => {
                                    if (d?.data?.id === recordToEdit?.id) {
                                        return {
                                            ...d,
                                            data: {
                                                ...d?.data,
                                                ...formData,
                                            }
                                        };
                                    }

                                    return d;
                                }));
                            }}
                            onAdded={item => {
                                // let itemData = item;

                                // optionsData?.links?.forEach(l => {
                                //     delete itemData[l?.name];
                                // });

                                setDocumentsToLinkAfterSave(d => ([
                                    ...d,
                                    {
                                        data: item,
                                        document_type_id: optionsData?.document_type_id,
                                        documents: optionsData?.links?.map(l => ({
                                            document_type_id: l?.document_type_id,
                                            name: l?.name,
                                        })),
                                        link_type_id: optionsData?.link_type_id ?? 3,
                                    }
                                ]));
                            }}
                            editable={true} 
                            document_links={optionsData?.links}
                            columns={optionsData?.columns} />;
                    }
                }}
            </Field>
            {spacer && <Spacer />}
        </FieldWrapper>
    </Wrapper>;
}

export default FormField;

const mentionEncoder = (userId) => `/secure/ViewProfile?name=${userId}`;

const Section = styled.div`
margin-bottom: 10px;
margin-top: 40px;
border-bottom: 1px solid #eee;
padding-bottom: 10px;
display: flex;
cursor: pointer;
`;

const SectionTitle = styled.h4`
    flex: 1;
    padding-right: 20px;
`;

const SectionIcon = styled.div`

`;

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin-top: -15px;
`;

const LabelWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
`;

const FieldWrapper = styled.div`
    flex: 1;
`;


const RequiredLabel = styled.span`
    color: #DE350B;
    padding-left: 2px;
`;
class CustomJiraTransformer extends JIRATransformer {
    encodeMention(node, encoder) {
        const elem = this.doc.createTextNode(`@[${node.attrs.text.replace("@", "")}](${node.attrs.id})`);
        return elem;
    }
}

const SigPad = styled.div`
    border: 1px solid #eee;
    height: 200px;
    width: 500px;
`;

const HelpWrapper = styled.div`
    padding: 0 5px 0;
    cursor: help;
`;

const Label = styled.label`
font-size: 1em;
font-style: inherit;
font-weight: 600;
letter-spacing: -0.003em;
margin-top: 16px;
line-height: 24px;
color: #6B778C;
overflow-wrap: break-word;
min-width: 0px;
`;

const Spacer = styled.div`
    height: 15px;
`;