import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import { Radio } from "@atlaskit/radio";
import Lozenge from "@atlaskit/lozenge";

export default function Payment({ page }) {
    const [shipping, setShipping] = useState("dhl");
    const data = page?.data ?? {};
    const total = page?.data?.orders?.reduce((acc, order) => {
        // if status == oplacone return acc
        return acc + order?.positions?.reduce((acc, pos) => {
            return acc + parseFloat(pos["wartosc"] ?? 0);
        }, 0) ?? 0;        
    }, 0) + 10.00; // @todo Wysyłka
    // const data = {
    //     orderNo: "ABC-123",
    //     price: "254,50",
    //     total: "265,50",
    //     positions: [
    //         { name: "Sukienka wlepka", price: "100,00", num: 1 },
    //         { name: "Golf - guziki", price: "65,50", num: 1 },
    //         { name: "Komplet sweterkowy holiday lila", price: "89", num: 1 },
    //     ],
    // }; // @todo

    return <>
        <Helmet>
            <title>{page?.title}</title>
        </Helmet>
        <Wrapper>
            <Left>
                <Header>Zamówienie #{data.orderNo}</Header>
                <Section>
                    <SectionHeader>Otwarta paczka</SectionHeader>
                    <Checkbox size="large" defaultChecked label={<div>
                        Po zapłacie otwarta paczka nie zostanie wysłana. 
                        Będziesz mogła/mógł dołączyć do paczki kolejne zamówienie.
                    </div>} />

                    <OpenPackageSection>
                        Paczka zostanie zamknięta w ciągu 5 dni roboczych.
                        Dzień wysyłki to <strong>@TODO</strong>
                    </OpenPackageSection>
                </Section>
                <Section>
                    <SectionHeader>Dostawca</SectionHeader>
                    <Radio
                        value="dhl"
                        label="DHL - 10 zł"
                        name="shipping"
                        isChecked={shipping == "dhl"}
                        onChange={e => setShipping(e.target.value)}
                    />
                    <Radio
                        value="ups"
                        label="UPS - 10 zł"
                        name="shipping"
                        isChecked={shipping == "ups"}
                        onChange={e => setShipping(e.target.value)}
                    />
                    <Radio
                        value="gls"
                        label="GLS - 10 zł"
                        name="shipping"
                        isChecked={shipping == "gls"}
                        onChange={e => setShipping(e.target.value)}
                    />
                </Section>
                <Section>
                    <SectionHeader>Dane adresowe</SectionHeader>
                    <FlexLg>
                        <Main>
                            <Field label="Ulica" name="street" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} />
                                )}
                            </Field>
                        </Main>
                        <Block15Lg />
                        <div>
                            <Field label="Nr domu" name="house">
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} />
                                )}
                            </Field>
                        </div>
                    </FlexLg>
                    <FlexLg>
                        <div>
                            <Field label="Kod pocztowy" name="postal" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} />
                                )}
                            </Field>
                        </div>
                        <Block15Lg />
                        <Main>
                            <Field label="Miejscowość" name="city" isRequired>
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} />
                                )}
                            </Field>
                        </Main>
                    </FlexLg>
                </Section>
                <div style={{marginTop: "40px"}}>
                    <Button appearance="primary" shouldFitContainer height={50}>
                        Zapłać {!isNaN(total) ? total : "-"} zł
                    </Button>
                </div>
            </Left>
            <Right>
                <Summary>
                    <h3 style={{marginBottom: "25px"}}>Podsumowanie</h3>

                    {data?.orders?.map((order, i) => (
                        <>
                            <Flex>
                                <Main>
                                    <h4>Zamówienie #{i + 1}</h4>
                                </Main>
                                <div>
                                    <Lozenge appearance="inprogress">todo</Lozenge>
                                </div>
                            </Flex>
                            <SummaryList style={{opacity: "0.5"}}>
                                {order?.positions?.map(position => (
                                    <FlexListItem key={position?.name}>
                                        <Main>{position?.ilosc} x {position?.name}</Main>
                                        <RightAlign>{position?.wartosc}zł</RightAlign>
                                    </FlexListItem>
                                ))}
                            </SummaryList>
                        </>
                    ))}
                    <SummaryList>
                        <FlexListItem>
                            <Main>Koszty wysyłki</Main>
                            <RightAlign>10,00zł</RightAlign>
                        </FlexListItem>
                    </SummaryList>
                    <SummaryPrice>
                        <Flex>
                            <Main>
                                <strong>Razem</strong>
                            </Main>
                            <div>
                                <SummaryPriceHighlight>
                                    <span>{!isNaN(total) ? total : "-"}</span> zł
                                </SummaryPriceHighlight>
                            </div>
                        </Flex>
                    </SummaryPrice>
                </Summary>
            </Right>
        </Wrapper>
    </>;
}

const Wrapper = styled.div`
padding: 20px;
@media (min-width: 900px) {
    padding: 40px;
	display: flex;
	max-width: 1200px;
	margin: 0px auto;
}
`;

const Left = styled.div`
flex: 3;
`;

const Right = styled.div`
flex: 2;

margin-top: 40px;
@media (min-width: 900px) {
    padding: 20px;
    padding-left: 100px;
}
`;

const Header = styled.h1`
font-size: 25px;
margin: 0;
padding: 0;
`;


const Section = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid #eee;
`;

const SectionHeader = styled.h3`
font-size: 16px;
margin-bottom: 10px;
`;

const Summary = styled.div`
    background: #F5F5F7;
    padding: 25px;
    border-radius: 5px;
`;

const SummaryList = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
`;

const SummaryPrice = styled.div`

`;

const Flex = styled.div`
    display: flex;
    align-items: flex-end;
`;

const FlexListItem = styled.div`
display: flex;
align-items: flex-end;
margin: 10px 0;
`;

const FlexLg = styled.div`
@media (min-width: 900px) {
    display: flex;
    align-items: flex-end;
}
`;

const Main = styled.div`
    flex: 1;
`;

const SummaryPriceHighlight = styled.h2`
`;

const OpenPackageSection = styled.div`
    background: #F5F5F7;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0 0;
`;

const Block15Lg = styled.div`
@media (min-width: 900px) {
    width: 15px;
    height: 100%;
}
`;

const RightAlign = styled.div`
    text-align: right;
`;