/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Modal, { ModalTransition, ModalFooter } from "@atlaskit/modal-dialog";
import Form, { Field } from "@atlaskit/form";
import prepareFormData from "../../utils/prepare-form-data";
import styled from "styled-components";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import FormField from "../forms/form-field";
import SearchDocumentsByType from "./search-documents-by-type";
import DocumentTableFormFields from "./document-table-form-fields";
import { useTranslation } from "react-i18next";
import apiClient from "../../api/api-client";
import useAsync from "../../hooks/use-async";
import { toast } from "react-toastify";

export default function DocumentTableNewRecord({ open, onClose, fields, document_links = [], editable, columns = [], document, documentTypeId, linkTypeId = 3 }) {
    const [ filters, setFilters ] = useState({});
    const [ links, setLinks ] = useState({});
    const { t } = useTranslation();
    const { run, isPending } = useAsync();

    const onSubmit = (data) => {
        run(apiClient(`workflow/document-type/${documentTypeId}/document`, { data: {
            ...data,
            links: [
                // { id: document?.id, type: linkTypeId },
                ...document_links?.map(documentLink => {
                    const linkName = documentLink.name;
                    const linkData = links[linkName] ? links[linkName] : null;
                    if (!linkData) return;
                    return { id: linkData.value, type: 3 };
                }).filter(i => i != null)
            ]
        } }))
            .then(response => {
                const id = response.id;

                Promise.all([
                    run(apiClient(`document/${document?.id}/link/${id}/type/${linkTypeId}`, { method: "POST" })),
                    // ...document_links?.map(documentLink => {
                    //     const linkName = documentLink.name;
                    //     const linkData = links[linkName] ? links[linkName] : null;
                    //     if (!linkData) return;
                    //     return run(apiClient(`document/${id}/link/${linkData.value}/type/3`, { method: "POST" }));
                    // }),
                ])
                    .then(() => onClose())
                    .catch(() => toast.error(t("unknown_error")));

            })
            .catch(() => {
                toast.error(t("new_error"));
            });
    };

    useEffect(() => {
        if (editable) return;

        setFilters({});
        setLinks({});
    }, [ editable ]);

    return <>
        <ModalTransition>
            {open && (
                <Modal
                    onClose={() => {
                        onClose();
                    }}
                    heading={t("document_table_new_record_heading")}
                    scrollBehavior="outside"
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                >
                    <Wrapper>
                        <DocumentTableFormFields 
                            isDisabledByLinks={document_links?.filter(l => l?.type === "link")?.filter(link => link?.required === "true" || link?.required === true).some(l => !links[l?.name])}
                            fields={fields} columns={columns} isPending={isPending} onSubmit={data => {
                                onSubmit(data);
                            }} onClose={onClose}>
                            <div>
                                {document_links?.map(link => (<div key={link?.id}>
                                    {link?.type === "filter" && <FieldWrapper>
                                        <Field label={link?.label} name={link?.name}>
                                            {({ fieldProps }) => {
                                                return <SearchDocumentsByType 
                                                    value={filters ? filters[link?.name] || null : null}
                                                    selectedDocumentId={filters ? filters[link?.name]?.value || null : null}
                                                    selectedDocumentTypeId={link?.document_type_id}
                                                    typeId={link?.document_type_id} 
                                                    document={document}
                                                    onChange={e => {
                                                        setFilters(f => ({ ...f, [link?.name]: e}));
                                                    }} />;
                                            }}
                                        </Field>
                                    </FieldWrapper>}
                                    {link?.type === "link" && <FieldWrapper>
                                        <Field label={link?.label} name={link?.name} 
                                            isRequired={link?.required === "true" || link?.required === true}>
                                            {({ fieldProps }) => {
                                                return <SearchDocumentsByType 
                                                    value={links ? links[link?.name] || null : null}
                                                    selectedDocumentId={links ? links[link?.name]?.value || null : null}
                                                    selectedDocumentTypeId={link?.document_type_id}
                                                    typeId={link?.document_type_id}
                                                    filter={filters && filters[link?.filtered_by]}
                                                    link={link || null}
                                                    document={document}
                                                    isDisabled={isPending}
                                                    onChange={e => {
                                                        setLinks(l => ({ ...l, [link?.name]: e}));
                                                    }} />;
                                            }}
                                        </Field>
                                    </FieldWrapper>}
                                </div>))}
                            </div>
                        </DocumentTableFormFields>
                    </Wrapper>
                </Modal>
            )}
        </ModalTransition>
    </>;
}

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldWrapper = styled.div`
    margin-top: 20px;
`;