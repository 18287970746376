import React from "react";
import { Helmet } from "react-helmet";
import Spinner from "../components/ui/spinner";
import CmsLayout from "../layouts/cms-layout";
import useCms from "../hooks/use-cms";
import SectionMessage from "@atlaskit/section-message";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Payment from "../components/cms/payment";

function Cms() {
    const { namespace, uuid } = useParams();
    const { page, isPending, isError } = useCms(namespace, uuid); 

    if (isError) {
        return <>
            <Helmet>
                <title>404 - Nie znaleziono</title>
            </Helmet>
            <ErrorWrapper>
                <SectionMessageWrapper>
                    <SectionMessage appearance="error" title={<h1>404</h1>}>
					Strony nie znaleziono.
                    </SectionMessage>
                </SectionMessageWrapper>
            </ErrorWrapper>
        </>;
    }

    if (page?.template) {
        switch (page?.template) {
        case "payment":
            return <Payment page={page} />;
        }
    }

    return <>
        <Helmet>
            <title>{page?.title}</title>
        </Helmet>
        <CmsLayout>
            {
                isPending
                    ? <Spinner />
                    : <div dangerouslySetInnerHTML={{__html: page?.html}} />
            }
        </CmsLayout>
    </>;
}

export default Cms;

const ErrorWrapper = styled.div`
    display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
`;

const SectionMessageWrapper = styled.div`
	width: 100%;
	max-width: 600px;
`;

const Wrapper = styled.div`
	padding: 40px;
`;