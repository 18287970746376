import React, { useEffect, useState } from "react";
import useDocumentFields from "../../hooks/use-document-fields";
import Spinner from "../ui/spinner";
import TextField from "@atlaskit/textfield";
import { Field } from "@atlaskit/form";
import FormField from "../forms/form-field";
import { format } from "date-fns";
import toBoolean from "../../utils/to-boolean";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import styled from "styled-components";

function NewDocumentForm({ documentTypeId, error, quickForm = false, onChange, initialValues = {}, data = {}, propagateFields, fields, isPending }) {
    const [ hidden, setHidden ] = useState([]);
    const [ hiddenCreate, setHiddenCreate ] = useState([]);

    useEffect(() => {
        const h = [];
        const hc = [];
        fields?.filter(f => f?.field?.type === "section")?.forEach(field => {
            try {
                const options = JSON.parse(field?.options[0]);
                if (options?.hidden) {
                    h.push(field?.sectionId);
                }
                if (options?.hiddenCreate) {
                    hc.push(field?.sectionId);
                }
            } catch (e) {
                //
            }
        });
        setHidden(h);
        setHiddenCreate(hc);
    }, [ fields?.length, documentTypeId ]);

    const toggleSection = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [ ...h, sectionId ]);
        }
    };

    if (isPending) {
        return <Spinner />;
    }

    const onFieldChanged = (fieldName, value) => {
        fields?.map(field => {
            let column = {};

            try {
                column = JSON.parse(field?.options);
            } catch (err) {
                console.log(err);
            }

            if (!column?.computed) return;
            
            const isA = column?.computed?.a === fieldName;
            const isB = column?.computed?.b === fieldName;

            if (!(isA || isB)) return;

            const aValue = isA ? value : data[column?.computed?.a];
            const bValue = isB ? value : data[column?.computed?.b];
            const op = column?.computed?.op;

            if (!aValue || !bValue || !op) return;

            let newValue;
            switch(op) {
            case "mult":
                newValue = Number(aValue) * Number(bValue);
                break;
            default:
                return;
            }

            onFieldChanged(field?.name, newValue);
            onChange(field, newValue);
        });
    };

    return <>
        {fields
            .filter(f => !toBoolean(f.hidden) && !toBoolean(f.read_only))
            .filter(f => quickForm ? toBoolean(f.quick_form) : true)
            .filter(f => f?.field?.type !== "list")
            .filter(f => f?.field?.type !== "widgets")
            // .filter(f => f?.field?.type !== "message")
            .map(field => {
                const inputProps = {
                    key: field.id,
                    name: field.name,
                    // label: field?.field?.type === "checkbox" ? null : field.label,
                    // isRequired: !!field.required,
                    defaultValue: field.field.type === "date" ? format(new Date(), "yyyy-MM-dd") : undefined,
                    isInvalid: error && error?.errors ? error?.errors[field.name] : false,
                    options: field?.options || [],
                    table_column: field?.table_column || [],
                    isDisabled: toBoolean(field?.read_only ?? 0),
                };

                if (initialValues[field.name]) {
                    inputProps.defaultValue = initialValues[field.name];
                }

                if (field.field.type === "message") {
                    const rawOptions = field?.options?.[0];
                    let optionsData = {};
                    try {
                        optionsData = JSON.parse(rawOptions);
                    } catch (e) {
                        console.error(e);
                    //
                    }
                    return <SectionMessageWrapper>
                        <SectionMessage
                            title={optionsData?.title}
                            appearance={optionsData?.appearance}
                            actions={optionsData?.actions?.map(a => (
                                <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                            ))}
                        >
                            {optionsData?.body}
                        </SectionMessage>
                    </SectionMessageWrapper>;
                }
                
                return <FormField key={field.id} 
                    inputProps={inputProps} 
                    field={field} 
                    onChange={(field, d) => {
                        onChange(field, d);
                        onFieldChanged(field?.name, d);
                    }} 
                    spacer
                    propagateFields={propagateFields}
                    onSectionToggle={sectionId => toggleSection(sectionId)}
                    sectionHidden={hidden?.includes(field?.sectionId)}
                    sectionHiddenCreate={hiddenCreate?.includes(field?.sectionId)}
                    value={data[field.name]} />;
            })}
    </>;
}

export default NewDocumentForm;

const SectionMessageWrapper = styled.div`
margin-top: 30px;
`;