import React, { useEffect, useState } from "react";
import PageHeader from "@atlaskit/page-header";
import AdminLayout from "../../layouts/admin-layout";
import apiClient from "../../api/admin-api-client";
import { toast } from "react-toastify";
import ButtonGroup from "@atlaskit/button/button-group";
import Button from "@atlaskit/button/custom-theme-button";
import useAsync from "../../hooks/use-async";
import useConfig from "../../hooks/use-config";
import TextArea from "@atlaskit/textarea";
import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";
import { useTranslation } from "react-i18next";

function AdminConfig() {
    const { t } = useTranslation();
    const [ name, setName ] = useState(null);
    const { data, isPending: isPendingConfig, reload } = useConfig(name);
    const { run, isPending: isCreating } = useAsync();
    const [ config, setConfig ] = useState(() => {
        // eslint-disable-next-line quotes
        return `{ "version": "0.0.1" }`;
    });
    useEffect(() => {
        try {
            setConfig(JSON.stringify(data?.config ?? { version: "0.0.1" }));        
        } catch (e) {
            setConfig("{ \"version\": \"0.0.1\" }");
        }
    }, [ data?.name ]);
    
    const isPending = name && isPendingConfig;

    const save = () => {
        run(apiClient("configs", { method: "PUT", data: {
            name,
            config: JSON.parse(config),
        }}))
            .then(() => {
                toast.success(t("admin_config_success"));
                reload();
            })
            .catch(() => {
                toast.error(t("admin_config_error"));
            });
    };

    const actionsContent = (
        <ButtonGroup>
            <Button appearance="primary" onClick={save} isLoading={isCreating}>
                {t("admin_config_save")}
            </Button>
        </ButtonGroup >
    );

    return <AdminLayout>
        <PageHeader actions={actionsContent}>
            {t("admin_config_heading")}
        </PageHeader>
        <div>
            <Select
                isLoading={isPending}
                isDisabled={isPending}
                onChange={e => setName(e?.value)}
                placeholder={t("admin_config_select_config")}
                options={[
                    { label: t("admin_config_select_option_link_types"), value: "types_map" },
                    { label: t("admin_config_select_option_field_types"), value: "fields_map" },
                    { label: t("admin_config_notification_webhook"), value: "notification_webhook" },
                    { label: t("admin_config_ignored_dts"), value: "ignored_dts" },
                    { label: t("admin_config_email_notifications"), value: "email_notifications" },
                    { label: "Facebook", value: "facebook" },
                    { label: "Dashboard", value: "dashboard" }
                ]}
            />
        </div>
        {name && <Field name="config" label="Config" isRequired autoFocus isDisabled={isPending}>
            {({ fieldProps }) => <TextArea
                {...fieldProps}
                isMonospaced
                value={config}
                onChange={e => setConfig(e?.target?.value)}
                minimumRows={20}
            />}
        </Field>}
    </AdminLayout>;
}

export default AdminConfig;
