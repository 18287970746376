export default function documentsListResponse(response) {
    return {
        data: response.data.map(row => ({
            uuid: row?.uuid,
            name: findName(row),
            id: row.id,
            category: row?.category,
            values: reduceValues(row.values),
            status: row.status,
            links: row?.links || [],
        })),
        pagination: {
            total: response?.meta?.total,
            per_page: response?.meta?.per_page,
            page: response?.meta?.current_page,
        }
    };
}

function reduceValues(values) {
    return values.reduce((acc, i) => {
        acc[i.name] = i.value;
        return acc;
    }, {});
}

function findName(row) {
    const nameValue = row?.values?.find(i => i.name === "name");
    return nameValue?.value;
}