import React from "react";
import { Link } from "react-router-dom";
import useDocument, { useDocumentWithoutDt } from "../../hooks/use-document";
import styled from "styled-components";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import slugify from "slugify";

export default function DisplayDocumentByType({ id, documentTypeId, margin = true, height = "30px" }) {
    const { isPending, document } = useDocumentWithoutDt(id);

    if (isPending) return <div>-</div>;

    return <DocumentFrame height={height}>
        <DocumentLink document={document} margin={margin} height={height} />
    </DocumentFrame>;
}

function DocumentLink({ document, margin, height }) {
    const space = document?.document_type?.space;
    if (!space) return null;
    const nameField = document?.document_type?.fields?.find(i => i.name === "name");
    const name = document?.values[nameField.id];
    if (!name) return null;
    const path = `${slugify(space?.category?.module?.name)}/${space?.category?.slug ?? slugify(space?.category?.name || "")}/${space?.slug}`.toLowerCase();
    return <DocumentLinkWrapper margin={margin}>
        <Link to={`/${path}/${document.id}`}>
            <DocumentLinkRow height={height}>
                {name}
            </DocumentLinkRow>
        </Link>
    </DocumentLinkWrapper>;
}


const DocumentLinkWrapper = styled.div`
    font-size: 14px;
    margin-bottom: ${props => props.margin ? "15px" : "0px"};
    margin-top: 5px;
`;

const DocumentLinkRow = styled.div`
    height: ${props => props.height};
    display: flex;
    font-weight: 600;
    align-items: center;
`;

const DocumentLinkIcon = styled.div`
    width: 16px;
    margin-right: 5px;
`;

const DocumentFrame = styled.div`
    display: inline-block;
    width: auto;
    height: ${props => props.height};
    padding: 2px 5px;
    margin: 0px 0;
    overflow: hidden;
    position: relative;
`;